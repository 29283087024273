import * as React from 'react';
import RcTree, { TreeNode } from 'rc-tree';
import classNames from 'classnames';
import DirectoryTree from './DirectoryTree';
import { ConfigContext } from '../config-provider';
import collapseMotion from '../_util/motion';
import renderSwitcherIcon from './utils/iconUtil';
import dropIndicatorRender from './utils/dropIndicator';
const Tree = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, virtual } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, showIcon, showLine, switcherIcon, blockNode, children, checkable, selectable, } = props;
    const prefixCls = getPrefixCls('tree', customizePrefixCls);
    const newProps = Object.assign(Object.assign({}, props), { showLine: Boolean(showLine), dropIndicatorRender });
    return (<RcTree itemHeight={20} ref={ref} virtual={virtual} {...newProps} prefixCls={prefixCls} className={classNames({
            [`${prefixCls}-icon-hide`]: !showIcon,
            [`${prefixCls}-block-node`]: blockNode,
            [`${prefixCls}-unselectable`]: !selectable,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className)} direction={direction} checkable={checkable ? <span className={`${prefixCls}-checkbox-inner`}/> : checkable} selectable={selectable} switcherIcon={(nodeProps) => renderSwitcherIcon(prefixCls, switcherIcon, showLine, nodeProps)}>
      {children}
    </RcTree>);
});
Tree.TreeNode = TreeNode;
Tree.DirectoryTree = DirectoryTree;
Tree.defaultProps = {
    checkable: false,
    selectable: true,
    showIcon: false,
    motion: Object.assign(Object.assign({}, collapseMotion), { motionAppear: false }),
    blockNode: false,
};
export default Tree;
