//created by Peggy on 2021/5/19
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import "./contribution.less";

import useConfirm from "@/components/Modal/Confirm";
import { admin_router_finance_order_detail } from "@/adminApp/routers";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { paymentTypes, statusTypes } from "@/types/enum";
import {
  orderList,
  contributionTag,
  contributionDelete,
  orderEditTags,
  contributionTagAdd,
  contributionTagDelete,
  contributionTagUpdate,
} from "@/adminApp/apis/finance-api";
import { ReactComponent as Filter } from "@/images/filter.svg";
import DatePicker from "@/components/DatePicker";
import queryString from "query-string";
import { shareLink } from "@/api/document-api";
import { getEnv } from "@/libs/cfg";
import { EditOutlined } from "@ant-design/icons";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

const List = () => {
  const history = useHistory();

  const [tagForm] = Form.useForm();
  const [tagModal, setTagModal] = useState<object | boolean>(false);

  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>Are you sure you want to permanently delete this orders ?</p>
    </div>,
    {
      title: "Delete ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        contributionDelete(id).then(() => {
          getList();
          message.success("Delete successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/contribution/order/export-csv?${queryString.stringify({
        ...others,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  const handleViewOrder = (id: any) => {
    window.open(admin_router_finance_order_detail.replace(":id/:edit(edit)?", id), "_blank");
  };

  const handleShowTagModal = (row: any) => {
    setTagModal(row);
    if (row !== true) {
      const { id, tag } = row;
      tagForm.setFieldsValue({
        id,
        tags: tag || [],
      });
    }
  };

  const handleTagFinish = () => {
    setLoading(true);
    const values = tagForm.getFieldsValue();
    if (tagModal === true) {
      contributionTagAdd(values)
        .then((data) => {
          message.success("Add successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getTags();
        })
        .catch(() => setLoading(false));
    }else{
      orderEditTags(values)
        .then(() => {
          message.success("Save successfully");
          tagForm.resetFields();
          setTagModal(false);
          getList();
        })
        .finally(() => setLoading(false));
    }
  }

  const handleDelete = (row: any) => {
    deleteShow(row.id);
  };

  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(false);
  const handleViewNote = (row: any) => {
    setNoteModal(true);
    const { donorNote } = row;
    noteForm.setFieldsValue({
      note: donorNote,
    });
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [shareForm] = Form.useForm();
  const [showShare, setShowShare] = useState<any>(undefined);
  const handleSubmitShare = () => {
    setLoading(true);
    shareForm
      .validateFields()
      .then((values) => {
        shareLink({ ...values, url: `${getEnv("ADMIN_HOST")}/sign-in?to=` + admin_router_finance_order_detail.replace(":id/:edit(edit)?", showShare.id), title: 'Order' })
          .then((_) => {
            _?.success && message.success("succeed.");
            onCancelShare();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancelShare = () => {
    shareForm.resetFields();
    setLoading(false);
    setShowShare(undefined);
  };
  //handler end

  const [tagList, setTagList] = useState<any[]>([]);
  const getTags = () => {
    contributionTag().then((data) => setTagList(data));
  };

  useEffect(() => {
    getTags();
  }, []);

  // -search
  const column: any[] = [
    {
      title: "Product type",
      dataIndex: "productType",
      width: 130,
      fixed: "left",
    },
    {
      title: "Donation type",
      dataIndex: "donationType",
      fixed: "left",
      width: 150,
      render: (text: string[]) => text || "N/A",
    },
    {
      title: "Program name",
      dataIndex: "eventName",
      width: 150,
      fixed: "left",
      render: (text: string[]) => text || "N/A",
    },
    {
      title: "Frequency",
      width: 110,
      dataIndex: "frequency",
      render: (text: string, row: any) =>
        text === "once"
          ? "One Time"
          : text === "month"
            ? "Recurring - Monthly "
            : "Recurring - Anually",
    },
    {
      title: "Receiver name",
      width: 150,
      dataIndex: "receiverName",
    },
    {
      title: "Buyer first name",
      width: 180,
      dataIndex: "donorName",
      render: (text: string, item: any) => item.donorFirstName || (text?.split(/\s+/) || [''])[0]
    },
    {
      title: "Buyer last name",
      width: 180,
      dataIndex: "donorName",
      render: (text: string, item: any) => item.donorLastName || (text?.split(/\s+/) || [, ''])[1]
    },
    {
      title: "Sub total",
      width: 130,
      dataIndex: "subTotal",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Grand total",
      width: 130,
      dataIndex: "amount",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },

    {
      title: "Discount",
      width: 100,
      dataIndex: "discount",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Fee",
      width: 80,
      dataIndex: "fees",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Fee Covered",
      width: 130,
      dataIndex: "fees",
      render: (text: string, row: any) =>{
        if(text === '0'){
          return 'N/A'
        }else if(text !== '0' && row.cover === true){
          return 'YES'
        }else if(text !== '0' && row.cover === false){
          return 'NO'
        }
      }
    },
    {
      title: "Buyer email",
      dataIndex: "donorEmail",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      className: "artist-cell-roles",
      render: (status: string, record: any) => {
        const text =
          record.detailStatus === "not_processed"
            ? record.detailStatus
            : status;
        return (
          <Tag color={statusTypes[text] ? statusTypes[text]?.color : "gray"}>
            {statusTypes[text] ? statusTypes[text]?.label : text}
          </Tag>
        );
      },
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      width: 180,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "New / Renewal",
      dataIndex: "isNew",
      width: 140,
      key: "isNew",
      render: (text: string) => <Tag color={text === 'New' ? 'green' : "orange"}>{text}</Tag>,
    },
    {
      title: "Coupon",
      dataIndex: "coupon",
      width: 100,
      className: "ellipsis-custom-cell",
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text || "N/A",
    },
    {
      title: "Check number",
      width: 150,
      dataIndex: "checkNumber",
    },
    {
      title: "Invoice number",
      width: 150,
      dataIndex: "orderId",
    },
    {
      title: "Transaction tag",
      dataIndex: "tag",
      className: "ellipsis-custom-cell",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text?.join(",") || "N/A",
    },
    {
      title: "Approved for QB",
      width: 170,
      dataIndex: "approveQuickBook",
      render: (text: string[]) => (text ? "Yes" : "No"),
    },
    {
      title: "Date on check",
      dataIndex: "checkDate",
      width: 150,
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Scheduled Pmt Date",
      width: 180,
      dataIndex: "schedulePaymentDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Date created",
      width: 130,
      dataIndex: "createDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleViewOrder(row.id)}>
                  View order
                </Menu.Item>
                <Menu.Item onClick={() => handleViewOrder(row.id + "/edit")}>
                  Edit order
                </Menu.Item>
                <Menu.Item onClick={() => handleShowTagModal(row)}>
                  Edit transaction tag
                </Menu.Item>
                <Menu.Item onClick={() => handleViewNote(row)}>
                  View donor notes
                </Menu.Item>
                <Menu.Item onClick={() => setShowShare(row)}>
                  Share link
                </Menu.Item>
                {/*<Menu.Item onClick={() => {}}>View donor info</Menu.Item>*/}
                {(["donation_to_artist", "donation_to_the_field", "crowdfunding", "donation_to_the_field_crowd"].includes(row.type) || row.paymentMethod === "check") && (
                  <Menu.Item onClick={() => handleDelete(row)}>
                    Delete
                  </Menu.Item>
                )}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const { search } = useLocation()
  const state = queryString.parse(search)
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    status: Object.keys(statusTypes).filter(_ => _ !== 'failed'),
    ...{ ...(state as any), date: (state as any).date?.map((_: string) => dayjs(_)) },
  });
  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize();
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };
  const handleFilterFinish = ({ startAmount, endAmount, ...values }: any) => {
    setSelectedRows([]);
    setTableData([]);
    // const [startAmount, endAmount] = amount?.split(",");
    setParams({
      ...params,
      ...values,
      startAmount,
      endAmount,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setText("");
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  const [editingTag, setEditingTag] = useState<{ id: number, name: string, del: boolean }>()
  const saveEditTag = async () => {
    if (!editingTag) {
      return
    }
    if (editingTag.del) {
      const res = await contributionTagDelete(editingTag!.id)
      if (res?.success) {
        message.success('Delete Successfully')
        getTags()
      }

    } else {
      const res = await contributionTagUpdate(editingTag.id, editingTag.name)
      if (res?.success) {
        message.success('Save Successfully')
        getTags()
      }
    }
    setEditingTag(undefined)
    getList()
  }

  const getList = () => {
    let _data = params;
    orderList({ ..._data, finance: "order" })
      .then(({ items, meta, summary }: any) => {
        setTableData(items);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="contribution-container admin-common-container">
      <h1>Orders</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>Product type</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.productType}
              bordered={false}
              onChange={(e) => {
                // setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  productType: e,
                });
              }}
              placeholder="Select product types"
            >
              <Select.Option value="donation_to_the_field">
                Donate to The Field
              </Select.Option>
              <Select.Option value="donation_to_artist">
                Donate to Artist
              </Select.Option>

              <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
              <Select.Option value="fiscal_sponsorship">
                Fiscal sponsorship
              </Select.Option>
              <Select.Option value="grant">Grant rush fee</Select.Option>
              <Select.Option value="carryover_fee">Carryover fee</Select.Option>
              <Select.Option value="expedite_disbursement_fee">Expedite Disbursement fee</Select.Option>
              <Select.Option value="consult_fee">Consultation fee</Select.Option>
              <Select.Option value="consultation">Consultation</Select.Option>
              <Select.Option value="misc_fee">Misc fee</Select.Option>
              <Select.Option value="the_field_ticket">Program Registration</Select.Option>
            </Select>
          </div>
          <div className="field-form-items">
            <span>Transaction tag</span>
            <Select
              allowClear
              value={params?.tag}
              placeholder="Select transaction tags"
              mode="multiple"
              bordered={false}
              onChange={(e) => {
                // setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  tagCondition: "one_of",
                  tag: e,
                });
              }}
            >
              {tagList.map((item: any) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>Order Status</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.status}
              bordered={false}
              onChange={(e) => {
                // setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  statusCondition: "one_of",
                  status: e,
                });
              }}
              placeholder="select... "
            >
              {Object.entries(statusTypes).map((item: any) => (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1].label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              <Menu.Item onClick={() => handleShowTagModal(true)}>
                Add transaction tag
              </Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Orders</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        // rowSelection={{
        //   type: "checkbox",
        //   onChange: (keys, rows) => {
        //     // setSelectedRows(rows);
        //   },
        // }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          showTotal: (total: any) => `Total Orders :  ${total} `,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        showHeader={!!tableData?.length}
        sticky
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Donor note"
        className="common-modal admin-user-modal"
        visible={noteModal}
        forceRender
        centered
        maskClosable={false}
        onCancel={() => setNoteModal(false)}
        footer={[
          <Button type="primary" onClick={() => setNoteModal(false)}>
            ok
          </Button>,
        ]}
      >
        <Form form={noteForm} layout="vertical" name="form_note">
          <Form.Item
            className="textarea-wrap"
            label="Note"
            name="note"
            rules={[
              {
                whitespace: true,
                message: "note",
              },
            ]}
          >
            <Input.TextArea disabled placeholder="Note..." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search" name="keyword">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Form.Item label="date created" name="date">
            <DatePicker.RangePicker />
          </Form.Item>
          <div className="flex">
            <Form.Item label="Min Value" name="startAmount"><Input type="number" min={0} /></Form.Item>
            <Form.Item label="Max Value" name="endAmount" dependencies={['startAmount']} rules={[{
              validator: (_, val ) =>{
                const start = filterForm.getFieldValue('startAmount')
                if(start !== undefined){
                  if(!val){
                    return Promise.reject('Max Value is required')
                  }else if (+val < +start){
                    return Promise.reject('Max value should greater than or equals Min value')
                  }
                }
                return Promise.resolve()

              }
            }]}><Input type="number" min={1} /></Form.Item>
          </div>
          {/* <Form.Item label="Order Value" name="amount">
            <Select allowClear placeholder="Select a range" bordered={false}>
              <Select.Option value="0,100">Less than $100</Select.Option>
              <Select.Option value="100,500.001">$100 - $500</Select.Option>
              <Select.Option value="500,1000.001">$500 - $1000</Select.Option>
              <Select.Option value="1000.001,">Larger than $1000</Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item label="Frequency" name="frequency">
            <Select allowClear placeholder="select..." bordered={false}>
              <Select.Option value="once">One time</Select.Option>
              <Select.Option value="month">Monthly</Select.Option>
              <Select.Option value="year">Annually</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="New / Renewal" name="isNew">
            <Select allowClear placeholder="select..." bordered={false}>
              <Select.Option value="New">New</Select.Option>
              <Select.Option value="Renewal">Renewal</Select.Option>
            </Select>
          </Form.Item>
          <div className="flex">
            {/* <Form.Item label="Condition" name="paymentMethodCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item label="Payment method" name="paymentMethod">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {Object.entries(paymentTypes)
                  ?.slice(0, 3)
                  .map((item: any) => (
                    <Select.Option key={item[0]} value={item[0]}>
                      {item[1]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex">
            {/* <Form.Item label="Condition" name="paymentMethodCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item label="fee covered?" name="cover">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
                <Select.Option value="na">N/A</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {deleteComponent}
      <Modal
        width={670}
        title="Edit transaction tag"
        className="common-modal admin-user-modal"
        visible={!!tagModal}
        onOk={() => handleTagFinish()}
        okText="save"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={tagForm}
          layout="vertical"
          name="form_tag"
        >
          <p className="text-12">
          {tagModal === true
              ? "You are adding new tag to the system. You can use this tag to filter contributions later on."
              : "You can use this form below to add or edit the transaction tags that are assigned to this order."}
          </p>
          {tagModal === true ? (
            <>
              <Form.Item
                label="Transaction tag"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Transaction tag",
                  },
                ]}
              >
                <Input maxLength={250} placeholder="Transaction tag" />
              </Form.Item>
              <p className="tag-list">
                {tagList?.map((item) => (
                  <Tag closable onClose={() => {
                    setTagModal(false)
                    setEditingTag({ ...item, del: true })
                  }} key={item.name}>{item.name} <EditOutlined onClick={() => {
                    setTagModal(false)
                    setEditingTag({ ...item })
                  }} /></Tag>
                ))}
              </p>
            </>
          ) : (<><Form.Item noStyle name="id">
          <Input hidden disabled />
        </Form.Item>
        <Form.Item
          label="Transaction tag"
          name="tags"
          rules={[
            {
              type: "array",
              message: "Transaction tag",
            },
          ]}
        >
          <Select
            bordered={false}
            placeholder="Select..."
            mode="multiple"
          >
            {tagList?.map((item) => (
              <Select.Option key={item.name} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item></>)}
        </Form>
      </Modal>
      <Modal
        className="common-modal document-modal"
        title="Share Application Link"
        visible={!!showShare}
        onOk={handleSubmitShare}
        okButtonProps={{
          loading: loading,
        }}
        okText="send"
        cancelText="cancel"
        onCancel={onCancelShare}
        forceRender
        centered
      >
        <p className="des">
          You are shareing:
          <span style={{ fontWeight: "bold", paddingLeft: 6 }}>
            {showShare?.name}
          </span>
        </p>
        <Form form={shareForm} layout="vertical" name="form_inking">
          <Form.Item
            label="Reciever email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Input email",
              },
            ]}
          >
            <Input placeholder="Input email to share this link" />
          </Form.Item>
          <Form.Item
            label="Reciever name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Input reciever name",
              },
            ]}
          >
            <Input placeholder="Input reciever name" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="msg"
            rules={[
              {
                whitespace: true,
                message: "Input message",
              },
            ]}
          >
            <Input.TextArea
              minLength={5}
              style={{ height: 100 }}
              placeholder="Input message to send out this link"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={!!editingTag}
        okText={editingTag?.del ? "delete" : 'save'}
        className="common-modal admin-user-modal"
        centered
        width={570}
        closable={false}
        maskClosable={false}
        onCancel={() => setEditingTag(undefined)}
        onOk={saveEditTag}
        title={editingTag && !editingTag.del ? `Edit transaction tag [${tagList.find(_ => _.id === editingTag.id).name}]` : undefined}
      >
        {
          editingTag?.del ?
            <h1>
              Are you sure you want to delete [{editingTag?.name}]?
            </h1> :
            <Form layout="vertical">
            <Form.Item label='edit tag'>
              <Input value={editingTag?.name} onChange={e => setEditingTag({ ...editingTag!, name: e.target.value })} />
            </Form.Item>
            </Form>
        }
      </Modal>
    </div>
  );
};

export default List;
