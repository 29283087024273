var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import omit from 'rc-util/lib/omit';
import devWarning from '../_util/devWarning';
import Base from './Base';
const Text = (_a) => {
    var { ellipsis } = _a, restProps = __rest(_a, ["ellipsis"]);
    const mergedEllipsis = React.useMemo(() => {
        if (ellipsis && typeof ellipsis === 'object') {
            return omit(ellipsis, ['expandable', 'rows']);
        }
        return ellipsis;
    }, [ellipsis]);
    if (process.env.NODE_ENV !== 'production')
        devWarning(typeof ellipsis !== 'object' ||
            !ellipsis ||
            (!('expandable' in ellipsis) && !('rows' in ellipsis)), 'Typography.Text', '`ellipsis` do not support `expandable` or `rows` props.');
    return <Base {...restProps} ellipsis={mergedEllipsis} component="span"/>;
};
export default Text;
