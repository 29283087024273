var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import Menu from '../menu';
import { ConfigContext } from '../config-provider';
import devWarning from '../_util/devWarning';
import { cloneElement } from '../_util/reactNode';
function getBreadcrumbName(route, params) {
    if (!route.breadcrumbName) {
        return null;
    }
    const paramsKeys = Object.keys(params).join('|');
    const name = route.breadcrumbName.replace(new RegExp(`:(${paramsKeys})`, 'g'), (replacement, key) => params[key] || replacement);
    return name;
}
function defaultItemRender(route, params, routes, paths) {
    const isLastItem = routes.indexOf(route) === routes.length - 1;
    const name = getBreadcrumbName(route, params);
    return isLastItem ? <span>{name}</span> : <a href={`#/${paths.join('/')}`}>{name}</a>;
}
const getPath = (path, params) => {
    path = (path || '').replace(/^\//, '');
    Object.keys(params).forEach(key => {
        path = path.replace(`:${key}`, params[key]);
    });
    return path;
};
const addChildPath = (paths, childPath = '', params) => {
    const originalPaths = [...paths];
    const path = getPath(childPath, params);
    if (path) {
        originalPaths.push(path);
    }
    return originalPaths;
};
const Breadcrumb = (_a) => {
    var { prefixCls: customizePrefixCls, separator = '/', style, className, routes, children, itemRender = defaultItemRender, params = {} } = _a, restProps = __rest(_a, ["prefixCls", "separator", "style", "className", "routes", "children", "itemRender", "params"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    let crumbs;
    const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);
    if (routes && routes.length > 0) {
        // generated by route
        const paths = [];
        crumbs = routes.map(route => {
            const path = getPath(route.path, params);
            if (path) {
                paths.push(path);
            }
            // generated overlay by route.children
            let overlay;
            if (route.children && route.children.length) {
                overlay = (<Menu>
            {route.children.map(child => (<Menu.Item key={child.path || child.breadcrumbName}>
                {itemRender(child, params, routes, addChildPath(paths, child.path, params))}
              </Menu.Item>))}
          </Menu>);
            }
            return (<BreadcrumbItem overlay={overlay} separator={separator} key={path || route.breadcrumbName}>
          {itemRender(route, params, routes, paths)}
        </BreadcrumbItem>);
        });
    }
    else if (children) {
        crumbs = toArray(children).map((element, index) => {
            if (!element) {
                return element;
            }
            if (process.env.NODE_ENV !== 'production')
                devWarning(element.type &&
                    (element.type.__ANT_BREADCRUMB_ITEM === true ||
                        element.type.__ANT_BREADCRUMB_SEPARATOR === true), 'Breadcrumb', "Only accepts Breadcrumb.Item and Breadcrumb.Separator as it's children");
            return cloneElement(element, {
                separator,
                key: index,
            });
        });
    }
    const breadcrumbClassName = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<div className={breadcrumbClassName} style={style} {...restProps}>
      {crumbs}
    </div>);
};
Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Separator = BreadcrumbSeparator;
export default Breadcrumb;
